import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {NumberFormat} from "../../global/helpers/ToHumansString";
import {Truncate} from "../../global/helpers/StringHelper";
import {Link} from "react-router-dom";
import {bazaarCount} from "../redux/bazaarCRUD";
import {regions} from "../../cities/redux/cityCRUD";

type RegionModel = {
  id: string
  name: string
  link: string
  bazaarCount: number
}

const Regions: React.FC = () => {
  const intl = useIntl()

  const [bazaarsCount, setBazaarsCount] = useState(0)
  const [regionList, setRegionList] = useState<RegionModel[]>([])

  const loadData = () => {
    bazaarCount().then(response=>{
      if (response.status === 200) {
        setBazaarsCount(Number(response.data))
      }
    })

    regions(intl.locale, 16).then(response=>{
      if (response.status === 200) {
        const list = response.data.map((e: any)=>{
          const m: RegionModel ={
            id: e.id,
            name: e.name,
            link: `/bazaars/from-city/${e.slug}`,
            bazaarCount: e.bazaar_count,
          }
          return m
        })
        setRegionList(list)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [])

  return <section className="job-categories border-bottom-0">
    <div className="auto-container">
      <div className="d-sm-flex align-items-center justify-content-sm-between wow fadeInUp">
        <div className="sec-title">
          <h1>{intl.formatMessage({id: 'Regions'})}</h1>
          <div className="text">{intl.formatMessage({id: 'bazaars count by country'}, {count: NumberFormat(bazaarsCount)})}</div>
        </div>
      </div>
      <div className="row wow fadeInUp">
        {regionList.map(e=><div key={e.id} className="category-block at-home18 col-lg-3 col-md-6 col-sm-12">
          <div className="inner-box">
            <div className="content">
              <span className="icon flaticon-maps-and-flags"></span>
              <h4 title={e.name}>{Truncate(e.name, 18)}</h4>
              <p>{`(${intl.formatMessage({id: 'has bazaar count'}, {count: NumberFormat(e.bazaarCount)})})`}</p>
            </div>
          </div>
        </div>)}
      </div>
    </div>
  </section>
}

export default Regions