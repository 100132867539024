import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import {PaymentModel} from "../models/PaymentModel";
import {paymentCheck} from "../redux/paymentCRUD";

const PayButton: React.FC<PaymentModel> = ({id, name}) => {
  const intl = useIntl()

  const [isPay, setIsPay] = useState(false)

  const loadData = () => {
    if (id.length === 36 && name.length > 0) {
      paymentCheck({id, name}).then(r=>{
        if (r.status === 200) {
          setIsPay(r.data.result?.toString() === 'false' && r.data.amount > 0)
        }
      })
    }
  }

  useEffect(()=>loadData(), [id, name])

  return <>{isPay ? <Link
    to={`/dashboard/choice-pay-method/${name}/${id}`}
    className='btn btn-success position-absolute top-0'
    style={{right: 20, width: 150}}
  >
    {intl.formatMessage({id: 'Pay'})}
  </Link> : null}</>
}
export default PayButton;