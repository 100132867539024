import React from "react"
import ReactPaginate from "react-paginate";
import SVG from "react-inlinesvg";
import {useIntl} from "react-intl";

type Props = {
  total: number
  pageCount: number
  currentPage: number
  setPage(page: number): void
}

const TablePaginate: React.FC<Props> = ({total, pageCount, currentPage, setPage}) => {
  const intl = useIntl()

  return <div className='d-flex flex-stack flex-wrap pt-10'>
    <div className='fs-6 text-muted text-gray-700'>
      {intl.formatMessage({id: 'Page info'}, {total, pageCount, currentPage})}
    </div>
    <ReactPaginate
      breakLabel="..."
      nextLabel={<i className="fa-solid fa-arrow-right"></i>}
      onPageChange={({selected})=>setPage(selected+1)}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel={<i className="fa-solid fa-arrow-left"></i>}
      renderOnZeroPageCount={null}
      containerClassName='pagination'
      pageClassName='page-item'
      pageLinkClassName='page-link'
      previousClassName='nav-item previous'
      nextClassName='nav-item next'
      previousLinkClassName='nav-link'
      nextLinkClassName='nav-link'
      activeClassName='active'
    />
  </div>
}
export default TablePaginate;