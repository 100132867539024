export const messages = {
  uz: {
    Home: "Bosh sahifa",
    Menu: "Menu",
    Search: "Izlash",
    'No photo': "Foto yo'q",
    Top: "Ko'p ko'rilgan",
    Latest: "Yangi",
    Recommended: "Tavsiya etamiz",
    "Bazaar calculator": "Bozor kalkulatori",
    "Logistic service": "Logistika xizmati",
    Advertisement: "E'lon",
    Advertisements: "E'lonlar",
    Delivery: "Yetkazish",
    "Seller advertisement": "Sotivchi e'loni",
    "Seller advertisements": "Sotivchi e'lonlari",
    "Buyer advertisement": "Xaridor e'loni",
    "Buyer advertisements": "Xaridor e'lonlari",
    "Delivery cargo advertisement": "Mashina E'loni",
    "Delivery goods advertisement": "Yuk E'loni",
    "Delivery cargo advertisements": "Mashina E'lonlari",
    "Delivery goods advertisements": "Yuk E'lonlari",
    Product: "Maxsulot",
    Products: "Maxsulotlar",
    "Login / Register": "Kirish / Ro'yxatdan o'tish",
    Phone: "Telefon",
    Password: "Parol",
    "Log In": "Kirish",
    "Don't have an account?": "Akkauntiz yo'qmi?",
    Signup: "Ro'yxatdan o'tish",
    "Bazaar prices in one": "Bozordagi narxlar bir joyda",
    Account: "Akkaunt",
    "Add advertisement": "E'lon joylash",
    "Advertisement type": "E'lon turi",
    Profile: "Profil",
    Dashboard: "Kabinet",
    "Logout": "Chiqish",
    Cabinet: "Kabinet",
    "My Profile": "Mening frofilim",
    "Required field": "{name} to'ldirilishi shart",
    "Length": "{name} uzunligi {len}",
    "Min length": "{name} minimum {len}",
    "Max length": "{name} maximum {len}",
    "Full name": "To'liq ism",
    Login: "Kirish",
    "SMS code": "SMS kod",
    "SMS code required 6 numbers": "SMS kod 6 ta raqam bo'lishi shart",
    Register: "Ro'yxatdan o'tish",
    "Do you have an account?": "Akkauntiz bormi?",
    Verify: "Tasdiqlash",
    Recent: "Qayta jo'natish",
    Error: "Xato",
    Success: "Mofaqiyatli",
    Save: "Saqlash",
    Birth: "Tug'ulgan sana",
    "Confirm password": "Parolni qaytarish",
    "Password and confirm password has ben equals!": "Parol va parol qaytarish bir xil bo'lishi kerak!",
    "File format .jpg or .png": "Tomonlar: 16:9 va fayl formati .jpg, .png va .webp",
    Delete: "O'chirish",
    "Are you sure": "Ishonchingiz komilmi",
    Cancel: "Bekor",
    Confirm: "Tasdiqlash",
    Required: "Shartli",
    Optional: "Shart emas",
    Offer: "Taklif",
    Yes: "Xa",
    No: "Yo'q",
    City: "Viloyat",
    District: "Tuman",
    "Product type": "Maxsulot turi",
    "Pay method": "To'lov usuli",
    Name: "Nomi",
    Uz: "Uz",
    Oz: "Oz",
    Ru: "Ru",
    Description: "Izox",
    Price: "Narx",
    Bazaar: "Bozor",
    Bazaars: "Bozorlar",
    Sale: "Sotish",
    Need: "Talab",
    Quantity: "Miqdori",
    Card: "Karta",
    Cash: "Naqt",
    Transfer: "O'tkazma",
    Edit: "Taxrirlash",
    Replace: "O'zgartirish",
    Image: "Rasm",
    Add: "Qo'shish",
    Category: "Kategoriya",
    Time: "Vaqt",
    Action: "Amal",
    Type: "Turi",
    Active: "Aktiv",
    Consideration: "Moderasiyada",
    Rejected: "Rad etilgan",
    Paying: "To'lov qilish",
    "Payment by PayMe": "PyMe yordamida to'lov",
    "Payment by Click": "Click yordamida to'lov",
    "Payment by balance": "Balansdan to'lov qilish",
    Enter: "Kiritish",
    Continue: "Davom etish",
    Status: "Status",
    Payment: "To'lov",
    "SMS code incorrect": "SMS kod notog'ri",
    Amount: "Summa",
    "Time out": "Vaqt tugadi",
    Pay: "To'lash",
    "Choice pay method": "To'lov usulini tanlang",
    PayMe: "PayMe",
    "Add balance": "Balansni to'ldirish",
    CurUz: "So'm",
    "Pay with balance": "Balansdan to'lash",
    Files: "Fayllar",
    "Page info": "Xammasi: {total} ta, saxifalar: {pageCount}, xozir: {currentPage}",
    Vehicle: "Avto rusmi",
    Weight: "Og'irligi",
    "Current location": "Xozirgi lokasiya",
    "Destination location": "Boradigan lokasiya",
    Date: "Sana",
    "Dispatch date": "Yo'lga chiqish sanasi",
    "Destination date": "Boradigan vaqti",
    Prices: "Narx - navo",
    "Ads days": "E'lon kunlari",
    "Bazaar calc": "Bozor kalkulatori",
    Seller: "Sotuvchi",
    Buyer: "Xaridor",
    "File download": "Fayl yuklash",
    Day: "Kun",
    Week: "Xafta",
    Month: "Oy",
    Year: "Yil",
    Start: "Boslanish",
    End: "Tuganlanish",
    "File create": "Fayl xosil qilish",
    Remove: "O'chirish",
    Calc: "Kalkulator",
    Download: "Yuklab olish",
    "New file download": "Yangi fayl yuklab olish",
    "Download files": "Yuklanadigan fayllar",
    Period: "Davr",
    "Period end": "Yakunlanish",
    "Contact us": "Bizni kontaktlarimiz",
    "Address full": "Toshkent shaxar, Mustaqillik 41 uy",
    "Bazaar prices from one place": "Bozordagi narxlar bir joyda",
    "products price review on every day": "+{count} turdagi mahsulot narxlari bilan har kuni tanishib boring",
    "Toshket, Andijon, Farg'ona, ...": "Toshket, Andijon, Farg'ona, ...",
    "Enter district name": "Tuman nomini yozing",
    "Find bazaar": "Bozorni izlash",
    "Popular searches": "Mashxur ishlashlar",
    "products": "{count} maxsulotlar",
    Regions: "Xududlar",
    "bazaars count by country": "Respublika bo'yicha dehqon bozorlar soni - {count} ta",
    "has bazaar count": "{count} ta bozor",
    "actual price count": "{count} ta aktual narx",
    "actual ads count": "{count} ta aktual e'lonlar",
    "All bazaars": "Xamma bozorlar",
    "Min and max prices": "Min va max narxlar",
    "View all": "Xammasini ko'rish",
    "Work days": "Ish kunlari",
    Mon: "Dush",
    Tue: "Sesh",
    Wed: "Chor",
    Thu: "Pay",
    Fri: "Jum",
    Sat: "Shan",
    Sun: "Yak",
    Kg: "Kg",
    "showing of items": "{showing} ta ko'rsatildi, {total} tadan",
    "Show more": "Ko'proq ko'rish",
    "Showing": "Ko'rish",
    "in total": "tadan",
    Show: "Ko'rish",
    to: "gacha",
    "Download now": "Xozir yuklab olish",
    "Download file": "Faylni yuklab olish",
    "Bazaar type": "Bozor turi",
    "Enter keywords": "Kalit so'zlarni kiriting",
    "Search by keywords": "Kalit so'zlar bo'yicha izlash",
    "Download file description": "Yuklab olinadigan fayl xaqida qisqacha ma'lumot",
    "Register page": "Ro'yxatdan o'tish saxifasi",
    "Login page": "Kirish saxifasi",
    "Product category": "Maxsulot kategoriyasi",
    "Back to list": "Ro'yxatga qaytish",
    Additional: "Qo'shimcha",
    Share: "Ulashish",
    Location: "Lokasiya",
    "Confirm products": "Maxshlotlarni tashdiqlash",
    "Edit products": "Maxshlotlarni taririlash",
    "Please wait": "Iltimos kuting",
    "This product bazaars": "Bu maxsulot bir yoki bir necha bozorlarda mavjud emas",
    "Add seller advertisement description": "Sotuvchi e'loni qo'shish izoxi",
  },
  oz: {
    Home: "Бош сахифа",
    Menu: "Меню",
    Search: "Излаш",
    'No photo': "Фото йўқ",
    Top: "Кўп кўрилган",
    Latest: "Янги",
    Recommended: "Тавсия этамиз",
    "Bazaar calculator": "Бозор калкулятори",
    "Logistic service": "Логистика хизмати",
    Advertisement: "Эълон",
    Advertisements: "Эълонлар",
    Delivery: "Йетказиш",
    "Seller advertisement": "Сотувчи эълони",
    "Seller advertisements": "Сотувчи эълонлари",
    "Buyer advertisement": "Харидор эълони",
    "Buyer advertisements": "Харидор эълонлари",
    "Delivery cargo advertisement": "Машина эълони",
    "Delivery goods advertisement": "Юк эълони",
    "Delivery cargo advertisements": "Машина эълонлари",
    "Delivery goods advertisements": "Юк эълонлари",
    Product: "Махсулот",
    Products: "Махсулотлар",
    "Login / Register": "Кириш / Рўйхатдан ўтиш",
    Phone: "Телефон",
    Password: "Парол",
    "Log In": "Кириш",
    "Don't have an account?": "Аккаунтиз йўқми?",
    Signup: "Рўйхатдан ўтиш",
    "Bazaar prices in one": "Бозор нархлари бир жойда",
    Account: "Аккаунт",
    "Add advertisement": "Эълон жойлаш",
    "Advertisement type": "Эълон тури",
    Profile: "Профил",
    Dashboard: "Кабинет",
    "Logout": "Чиқиш",
    Cabinet: "Кабинет",
    "My Profile": "Менинг профилим",
    "Required field": "{name} тўлдирилиши шарт",
    "Length": "{name}ни узунлиги {len}та",
    "Min length": "{name} минимум {len}",
    "Max length": "{name} максимум {len}",
    "Full name": "Тўлиқ исм",
    Login: "Кириш",
    "SMS code": "СМС код",
    "SMS code required 6 numbers": "СМС код 6 та рақамдан иборат бўлиши керак",
    Register: "Рўйхатдан ўтиш",
    "Do you have an account?": "Аккаунтиз борми?",
    Verify: "Тасдиқлаш",
    Recent: "Қайта жўнатиш",
    Error: "Хато",
    Success: "Мофақиятли",
    Save: "Сақлаш",
    Birth: "Туғилган сана",
    "Confirm password": "Паролни қайтариш",
    "Password and confirm password has ben equals!": "Парол ва парол қайтариш бир хил бўлиши шарт!",
    "File format .jpg or .png": "Fayl formati .jpg yoki .png",
    Delete: "Ўчириш",
    "Are you sure": "Ишончингиз комилми",
    Cancel: "Бекор",
    Confirm: "Тасдиқлаш",
    Required: "Шартли",
    Optional: "Шарт эмас",
    Offer: "Таклиф",
    Yes: "Ха",
    No: "Йўқ",
    City: "Вилоят",
    District: "Туман",
    "Product type": "Махсулот тури",
    "Pay method": "Тўлов усули",
    Name: "Номи",
    Uz: "Уз",
    Oz: "Ўз",
    Ru: "Ру",
    Description: "Изох",
    Price: "Нарх",
    Bazaar: "Бозор",
    Bazaars: "Бозорлар",
    Sale: "Сотиш",
    Need: "Талаб",
    Quantity: "Миқдори",
    Card: "Карта",
    Cash: "Нақт",
    Transfer: "Ўтказма",
    Edit: "Тахрирлаш",
    Replace: "Ўзгартириш",
    Image: "Расм",
    Add: "Қўшиш",
    Category: "Категория",
    Time: "Вақт",
    Action: "Амал",
    Type: "Тури",
    Active: "Актив",
    Consideration: "Модерация",
    Rejected: "Рад этилган",
    Paying: "Тўлов қилиш",
    "Payment by PayMe": "PyMe ёрдамида тўлов",
    "Payment by Click": "Click ёрдамида тўлов",
    "Payment by balance": "Балансдан тўлов қилиш",
    Enter: "Киритиш",
    Continue: "Давом этиш",
    Status: "Статус",
    Payment: "Тўлов",
    "SMS code incorrect": "СМС код нотўғри",
    Amount: "Сумма",
    "Time out": "Вақт тугади",
    Pay: "Тўлаш",
    "Choice pay method": "Тўлов усулини танланг",
    PayMe: "PayMe",
    "Add balance": "Балансни тўлдириш",
    CurUz: "Сўм",
    "Pay with balance": "Балансдан тўлаш",
    Files: "Файллар",
    "Page info": "Хаммаси: {total} та, сахифалар: {pageCount}, хозир: {currentPage}",
    Vehicle: "Авто русми",
    Weight: "Оғирлиги",
    "Current location": "Хозирги локасия",
    "Destination location": "Борадиган локасия",
    Date: "Сана",
    "Dispatch date": "Йўлга чиқиш санаси",
    "Destination date": "Борадиган сана",
    Prices: "Нарх - наво",
    "Ads days": "Эълон кунлари",
    "Bazaar calc": "Бозор калкулатори",
    Seller: "Сотувчи",
    Buyer: "Харидор",
    "File download": "Файл юклаш",
    Day: "Кун",
    Week: "Хафта",
    Month: "Ой",
    Year: "Йил",
    Start: "Бошланиш",
    End: "Туганланиш",
    "File create": "Файл хосил қилиш",
    Remove: "Ўчириш",
    Calc: "Калкулятор",
    Download: "Юклаб олиш",
    "New file download": "Янги файл юклаш",
    "Download files": "Юкланган файллар",
    Period: "Давр",
    "Period end": "Якунлаш",
    "Contact us": "Бизни контактларимиз",
    "Address full": "Компания тўлиқ манзили",
    "Bazaar prices from one place": "Бозордаги нархлар бир жойда",
    "products price review on every day": "+{count} турдаги махсулот нархлари билан хар куни танишиб боринг",
    "Toshket, Andijon, Farg'ona, ...": "Тошкент, Андижон, Фарғона, ...",
    "Enter district name": "Туман номини ёзинг",
    "Find bazaar": "Бозорни излаш",
    "Popular searches": "Машхур излашлар",
    "products": "{count} махсулотлар",
    Regions: "Худудлар",
    "bazaars count by country": "Республика бўйича дехқон бозорлари сони - {count} та",
    "has bazaar count": "{count} та бозор",
    "actual price count": "{count} та актуал нарх",
    "actual ads count": "{count} та актуал эълонлар",
    "All bazaars": "Хамма бозорлар",
    "View all": "Хаммасини кўриш",
    "Work days": "Иш кунлари",
    Mon: "Душ",
    Tue: "Сеш",
    Wed: "Чор",
    Thu: "Пай",
    Fri: "Жум",
    Sat: "Шан",
    Sun: "Якш",
    Kg: "Кг",
    "showing of items": "{showing} та кўрсатилди, {total} тадан",
    "Show more": "Кўпроқ кўриш",
    "Showing": "Кўрилди",
    "in total": "тадан",
    Show: "Кўриш",
    to: "гача",
    "Download now": "Хозир юклаб олиш",
    "Download file": "Файлни юклаб олиш",
    "Bazaar type": "Бозор тури",
    "Enter keywords": "Калит сўзни киритинг",
    "Search by keywords": "Калит сўзлар бўйича излаш",
    "Download file description": "Юклаб олинадиган файл хақида қисқача маълумот",
    "Register page": "Рўйхатдан ўтиш сахифаси",
    "Login page": "Кириш сахифаси",
    "Product category": "Махсулот категорияси",
    "Back to list": "Рўйхатга қайтиш",
    Additional: "Қўшимча",
    Share: "Улашиш",
    Location: "Локасия",
    "Confirm products": "Махсулотларни тасдиқлаш",
    "Edit products": "Махсулотларни тахрирлаш",
    "Please wait": "Илтимос кутинг",
    "This product bazaars": "Бу махсулот бир ёки бир нечта бозорда мавжуд эмас",
    "Add seller advertisement description": "Сотувчи эълони қўшиш изохи",
  },
  ru: {
    Home: "Главная",
    Menu: "Меню",
    Search: "Поиск",
    'No photo': "Нет фото",
    Top: "Топ просмотров",
    Latest: "Новый",
    Recommended: "Рекомендуем",
    "Bazaar calculator": "Калькулатор базара",
    "Logistic service": "Услуга логистики",
    Advertisement: "Объявление",
    Advertisements: "Объявлении",
    Delivery: "Доставка",
    "Seller advertisement": "Объявление продовца",
    "Seller advertisements": "Объявлении продавца",
    "Buyer advertisement": "Объявление покупателя",
    "Buyer advertisements": "Объявлении покупателя",
    "Delivery cargo advertisement": "Объявлеие машины",
    "Delivery goods advertisement": "Объявление груза",
    "Delivery cargo advertisements": "Объявлении машин",
    "Delivery goods advertisements": "Объявлении грузов",
    Product: "Продукт",
    Products: "Продукты",
    "Login / Register": "Войти / Зарегистрироваться",
    Phone: "Телефон",
    Password: "Парол",
    "Log In": "Войти",
    "Don't have an account?": "Нет аккаунта?",
    Signup: "Зарегистрироваться",
    "Bazaar prices in one": "Цены базара в одном месте",
    Account: "Аккаунт",
    "Add advertisement": "Разместить объявления",
    "Advertisement type": "Тип объявления",
    Profile: "Профиль",
    Dashboard: "Кабинет",
    "Logout": "Выйти",
    Cabinet: "Кабинет",
    "My Profile": "Мой профиль",
    "Required field": "{name} объязателен для заполнение",
    "Length": "длина поля {name} {len} символов",
    "Min length": "{name} минимум {len}",
    "Max length": "{name} максимум {len}",
    "Full name": "Полное имя",
    Login: "Войти",
    "SMS code": "СМС код",
    "SMS code required 6 numbers": "СМС код должен состоять из 6 цифр",
    Register: "Зарегистрироваться",
    "Do you have an account?": "Есть аккаунт?",
    Verify: "Подтвердить",
    Recent: "Отправить повторно",
    Error: "Ошибка",
    Success: "Успешно",
    Save: "Сохранить",
    Birth: "Дата рождения",
    "Confirm password": "Повторить пароль",
    "Password and confirm password has ben equals!": "Пароль и повтор пароля должни совпадать!",
    "File format .jpg or .png": "Формат файла .jpg или .png",
    Delete: "Удалить",
    "Are you sure": "Вы уверены",
    Cancel: "Отменить",
    Confirm: "Подтвердить",
    Required: "Объязательно",
    Optional: "Не объязательно",
    Offer: "Предложение",
    Yes: "Да",
    No: "Нет",
    City: "Область",
    District: "Район",
    "Product type": "Тип продукта",
    "Pay method": "Способ оплаты",
    Name: "Названия",
    Uz: "Уз",
    Oz: "Ўз",
    Ru: "Ру",
    Description: "Описание",
    Price: "Цена",
    Bazaar: "Базар",
    Bazaars: "Базары",
    Sale: "Продажа",
    Need: "Спрос",
    Quantity: "Количество",
    Card: "Карта",
    Cash: "Наличнымы",
    Transfer: "Перевод",
    Edit: "Редактировать",
    Replace: "Заменить",
    Image: "Фото",
    Add: "Просмотр",
    Category: "Категория",
    Time: "Время",
    Action: "Действие",
    Type: "Тип",
    Active: "Актив",
    Consideration: "Модерация",
    Rejected: "Отказ",
    Paying: "Оплатить",
    "Payment by PayMe": "Оплатить с помощью PyMe",
    "Payment by Click": "Оплатить с помощью Click",
    "Payment by balance": "Оплатить с баланса",
    Enter: "Ввести",
    Continue: "Продолжить",
    Status: "Статус",
    Payment: "Оплата",
    "SMS code incorrect": "СМС код неверный",
    Amount: "Сумма",
    "Time out": "Время вышло",
    Pay: "Оплатить",
    "Choice pay method": "Выберите способ оплаты",
    PayMe: "PayMe",
    "Add balance": "Пополнить баланс",
    CurUz: "Сум",
    "Pay with balance": "Оплатить с баланса",
    Files: "Файлы",
    "Page info": "Все: {total} шт, страниц: {pageCount}, сейчас: {currentPage}",
    Vehicle: "Марка автомобиля",
    Weight: "Вес",
    "Current location": "Текущая локация",
    "Destination location": "Локация доставки",
    Date: "Дата",
    "Dispatch date": "Дата отправки",
    "Destination date": "Дата доставки",
    Prices: "Цены",
    "Ads days": "Дни объявление",
    "Bazaar calc": "Калькулятор базара",
    Seller: "Продовец",
    Buyer: "Покупатель",
    "File download": "Загрузить файл",
    Day: "День",
    Week: "Неделя",
    Month: "Месяц",
    Year: "Год",
    Start: "Начало",
    End: "Окончения",
    "File create": "Создать файл",
    Remove: "Удалить",
    Calc: "Калькулятор",
    Download: "Загрузить",
    "New file download": "Загрузить новый файл",
    "Download files": "Загруженные файлы",
    Period: "Период",
    "Period end": "Завершение",
    "Contact us": "Наши контакты",
    "Address full": "Полный адрес компании",
    "Bazaar prices from one place": "Цены базара в одном месте",
    "products price review on every day": "+{count} цен продуктов наблюдайте каждый день",
    "Toshket, Andijon, Farg'ona, ...": "Ташкент, Андижан, Фергана, ...",
    "Enter district name": "Введите название района",
    "Find bazaar": "Поиск базара",
    "Popular searches": "Популярные поиски",
    "products": "{count} продуктов",
    Regions: "Регионы",
    "bazaars count by country": "Фермерских базаров по всей Республике - {count} шт",
    "has bazaar count": "{count} базаров",
    "actual price count": "{count} актуальных цен",
    "actual ads count": "{count} актуальных объявлении",
    "All bazaars": "Все базары",
    "View all": "Просмотреть все",
    "Work days": "Рабочие дни",
    Mon: "Пон",
    Tue: "Вто",
    Wed: "Сре",
    Thu: "Чет",
    Fri: "Пят",
    Sat: "Суб",
    Sun: "Вос",
    Kg: "Кг",
    "showing of items": "показано {showing}, из {total}",
    "Show more": "Посмотреть больше",
    "Showing": "Просмотреть",
    "in total": "из",
    Show: "Просмотреть",
    to: "до",
    "Download now": "Загрузить сейчас",
    "Download file": "Загрузить файл",
    "Bazaar type": "Тип базара",
    "Enter keywords": "Введите ключевое слово",
    "Search by keywords": "Поиск по ключевому слову",
    "Download file description": "Коротко о загружаемом файле",
    "Register page": "Страница регистрации",
    "Login page": "Страница входа",
    "Product category": "Категория продукта",
    "Back to list": "Вернутся в список",
    Additional: "Дополнительно",
    Share: "Поделтися",
    Location: "Локация",
    "Confirm products": "Подтвердить продукты",
    "Edit products": "Редактировать продукты",
    "Please wait": "Пожалуйста подождите",
    "This product bazaars": "Этот продук нет в одном или несколких базарах",
    "Add seller advertisement description": "Краткое описание добавление объявление продовца",
  },
}